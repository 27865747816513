<template>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          Filters
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Name</label>
                <b-form-input
                id="Name"
                placeholder="Enter Name"
                v-model="name"
                debounce="500"
                
                />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Region</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="regionOptions"
              label="name"
              class="w-100"
              :reduce="val => val.id"
              @input="(val) => regionChange(val)"
              v-model="regionId"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Status</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              label="name"
              class="w-100"
              :reduce="val => val.id"
              @input="(val) => statusChange(val)"
              v-model="status"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  import {
    BCard, BCardHeader, BCardBody, BRow, BCol, BFormInput, BFormGroup
  } from 'bootstrap-vue'
import M from 'minimatch';
  import vSelect from 'vue-select'
  
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BCardHeader,
      BCardBody,
      vSelect,
      BFormInput,
      BFormGroup
    },
    computed: {
        regionOptions (){
        return this.$store.getters['admin/GetRegions'] 
        }
    },
    data() {
        return {
            name: '',
            regionId: '',
            status: '',
            statusOptions : [
                { name: 'inActive', id: 0 },
                { name: 'active', id: 1 },
            ],
        }
    },
    methods: {
        regionChange(val){
            this.regionId = val
            this.refreshDatas()
        },
        statusChange(val) {
            this.status = val
            this.refreshDatas()
        },
        refreshDatas(){
            this.$parent.refreshData({name: this.name, regionId: this.regionId, status: this.status});
            // emit('refreshData', {name: this.name, regionId: this.regionId, status: this.status})
           
        }
    },
    watch: {
        name(val) {
            this.name = val
            this.refreshDatas()
        },
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
  