<template>
    <div>
        <div id="super_main_container">
          <div class="black_text" id="geocoder_text" >{{ geoCoderText }}</div>
          <div class="map_container">
              <input id="pac-input" class="controls" type="text" placeholder="Search by address"/>
              <div id="map" ref="map"></div>
              <img class="map_pin" src="./../../../public/pin.png"/>
          </div>
        <!-----------------------------------end of section_one_collect--------------------------------->
        </div><!--end of supermain container-->

        <!-- <button style="margin-right: 10px;" class="submitting" @click="prepareData">
            اختيار الموقع
            <i class="far fa-check"></i>
        </button> -->
    </div>
</template>

<script async defer
    src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDTf4eLlmZ6U98F3OR1k2B0Z-bqWc-4gXk&callback=initMap&libraries=places">
    </script>
<script>

export default {
    props: {
        coordinate: {
            type: String
        }
    },
  data() {
    return {
      name: '',
      geocoder: '',
      searchBox: '',
      current_lat:'',
      current_lng: '',
      latitute: '',
      longitude: '',
      center: { lat: 15.589077348075, lng: 32.551870323834 },
      markers: [],
      inValid: false,
      geoCoderText: '',
      pickupCountry: '',
      myCoordinate: { lat: 15.589077348075, lng: 32.551870323834 },

    }
  },
  mounted() {
    
    //   this.styleCodes.join(",");
    
    if (this.coordinate) {
        this.seprateCoordinate(this.coordinate)
    }
    this.initMap()
    this.geolocation()
  },
  methods: {

    seprateCoordinate(val) {
        const styleCodes = [];
        const tempStyleCodes = "4321test, test, 908test";

        const codes = val.split(/\s*,\s*/);
        
        this.myCoordinate = { lat: Number(codes[0]), lng: Number(codes[1]) }
        console.log(this.myCoordinate);
        this.center = { lat: codes[0], lng: codes[1] }

    },
    initMap(){
      if(this.myCoordinate.lat == 0 || this.myCoordinate.lng == 0){
        this.geolocation()
      }else{        
        this.latitute = this.myCoordinate.lat
        this.longitude = this.myCoordinate.lng
        this.center = this.myCoordinate
      }


      this.map = new google.maps.Map(document.getElementById('map'), {
        center: this.center,
        zoom: 12,
        mapTypeControl: false,
        fullscreenControl: false
      });


      google.maps.event.addListener(this.map, "center_changed", function() {
        var center = this.map.getCenter();
        var latitude = center.lat();
        var longitude = center.lng();

        var coordinate = latitude+','+longitude
        this.$emit('changeCoordinate', coordinate);
    }.bind(this));


      this.geocoder = new google.maps.Geocoder();
      // this.codeAddress(this.latitute, this.longitude)
      this.searchPlace()

      if(this.myCoordinate.lat == 0 || this.myCoordinate.lng == 0){
      }else{
        this.codeAddress(this.latitute, this.longitude)
      }
    },
    searchPlace(){
      const input = document.getElementById("pac-input");
      this.searchBox = new google.maps.places.SearchBox(input);
      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(input); // Bias the SearchBox results towards current map's viewport.
      this.map.addListener("bounds_changed", () => {
          this.searchBox.setBounds(this.map.getBounds());
      });
      this.placeDetails()
    },
    placeDetails(){
       // more details for that place.
      this.searchBox.addListener("places_changed", () => {
        const places = this.searchBox.getPlaces();
        if (places.length == 0) {
            return;
        } // Clear out the old markers.
        this.markers.forEach(marker => {
            marker.setMap(null);
        });
        this.markers = []; // For each place, get the icon, name and location.
        const bounds = new google.maps.LatLngBounds();
        places.forEach(place => {
          if (!place.geometry) {
              return;
          }
          const icon = {
              url: place.icon,
              size: new google.maps.Size(71, 71),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(17, 34),
              scaledSize: new google.maps.Size(25, 25)
          }; // Create a marker for each place.

          this.markers.push(
              new google.maps.Marker({
                  map,
                  icon,
                  title: place.name,
                  position: place.geometry.location
              })
          );
          if (place.geometry.viewport) {
              // Only geocodes have viewport.
              bounds.union(place.geometry.viewport);
          } else {
              bounds.extend(place.geometry.location);
          }
        });
        this.map.fitBounds(bounds);
      });
      
    },
    geolocation(){      
      if (navigator.geolocation) {        
        let self = this
        navigator.geolocation.getCurrentPosition(function(position) {
            var pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };
            self.latitute = position.coords.latitude;
            self.longitude = position.coords.longitude;
            self.map.setCenter(pos);
        }, function() {
            this.handleLocationError(true, this.map.getCenter());
        });
      } else {
          // Browser doesn't support Geolocation
          this.handleLocationError(false, this.map.getCenter());
      }
      let self = this
      google.maps.event.addDomListener(this.map, "idle", () => {
        this.latitute = this.map.getCenter().lat();
        this.longitude = this.map.getCenter().lng();
        self.codeAddress(self.latitute, self.longitude)
      });
      
    },
    codeAddress(latitute, longitude) {
      var addressdiv = document.getElementById('geocoder_text');
      const latlng = {
        lat: parseFloat(latitute),
        lng: parseFloat(longitude)
      };
      this.geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === "OK") {          
          if (results[0]) {
            var countryCode;
            results.forEach(function (addressItem) {
                let array_length = addressItem.address_components.length;
                countryCode = addressItem.address_components[array_length-1].short_name;
            });
            this.geoCoderText = results[0].formatted_address;
            addressdiv.innerHTML = results[0].formatted_address;
            this.pickupCountry = countryCode; 
          } else {}
        } else {
          }
      });
    },
    handleLocationError(browserHasGeolocation, pos) {},
    prepareData(){
      let coordinate = {lat: this.latitute, lng: this.longitude}
        let data = {
          coordinate: coordinate
        }
        // this.sendData(data)
    },
     sendData(data){  
        //   this.$store.commit('setIsLoading',true)
    //   axios
    //   .post("/tech/editCoordinate", data)
    //   .then(response => {
    //     $cookies.set('madrasati.schoolCoordinate', this.myCoordinate)
    //       this.$toastr.success('تم حفظ البيانات بنجاح', 'نجاح');
    //       this.$store.commit('setIsLoading',false)
    //       this.$router.push('/profile')
    //       })
    //   .catch(error => {
    //       if(error.response.data.responseCode == 102){
    //           this.allerrors = error.response.data.errors
    //       }else {
    //           this.$toastr.error('الرجاء المحاولة مرة اخرى', 'فشل');
    //       }                    
    //       this.$store.commit('setIsLoading',false)
    //   });
        
    }
  },
}
</script>


<style >
  .introInput{
    margin: 0; 
    font-family: inherit; 
    font-size: inherit; 
    line-height: inherit;
    height: 30px;
    width: 100%;
    margin-bottom: 20px;
  }
  
  #name-input{
    margin-top: 10px;
    border: 1px solid #adb1b4;
    border-radius: 5px;
    background-color: #fff;
    font-family: inherit;
    font-size: 15px;
    font-weight: 300;
    position: initial;
    left: 0px;
    right: 0px;
    margin-right: auto;
    margin-left: auto;
    padding: 5px;
    text-overflow: ellipsis;
    width: 80%;
    z-index: 9;
  }

  .map_container {
    width: 100%;
    height: 350px;
    position: relative;
    background-color: gray;
    margin-top: 15px;
}

#map,
#map2 {
    width: 100%;
    height: 100%;
}

.map_pin {
    pointer-events: none;
    /* background-image: url(images/pin.png); */
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50px;
    height: 50px;
    margin-left: -25px;
    margin-top: -50px;
    z-index: 9;
}


/************places style****************/

#description {
    font-family: inherit;
    font-size: 15px;
    font-weight: 300;
}

#infowindow-content .title {
    font-weight: bold;
}

#infowindow-content {
    display: none;
}

#map #infowindow-content {
    display: inline;
}

.pac-card {
    margin: 10px 10px 0 0;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    font-family: inherit;
}

#pac-container {
    padding-bottom: 12px;
    margin-right: 12px;
}

.pac-controls {
    display: inline-block;
    padding: 5px 11px;
}

.pac-controls label {
    font-family: inherit;
    font-size: 13px;
    font-weight: 300;
}

#pac-input,
#pac-input2 {
    margin-top: 10px;
    border: 1px solid #adb1b4;
    border-radius: 5px;
    background-color: #fff;
    font-family: inherit;
    font-size: 15px;
    font-weight: 300;
    position: absolute;
    left: 0px;
    right: 0px;
    margin-right: auto;
    margin-left: auto;
    padding: 5px;
    text-overflow: ellipsis;
    width: 80%;
    z-index: 9;
}

#pac-input:focus {
    border-color: #4d90fe;
}

#title {
    color: #fff;
    background-color: #4d90fe;
    font-size: 25px;
    font-weight: 500;
    padding: 6px 12px;
}

#target {
    width: 345px;
}

.sub_title {
    color: #6d6e71;
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
}

.black_text {
    color: #211b35;
    font-size: 16px;
}

.blue_text {
    color: #40b7e6;
    font-size: 16px;
}

.select_label_container {
    position: relative;
    margin-top: 15px;
    border: 1px solid #adb1b4;
    border-radius: 5px;
    width: 100%;
    float: left;
}

.select_label_container:after {
    content: "\f078";
    font: normal normal normal 17px/1 FontAwesome;
    color: #ffffff;
    background-color: #40b7e6;
    top: 0px;
    left: 0px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 12px;
    position: absolute;
    pointer-events: none;
}

.select_with_label {
    height: 40px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ffffff;
    background-color: #ffffff;
    padding: 10px;
    -webkit-font-smoothing: antialiased;
    font-family: inherit;
    font-size: 16px;
    appearance: none;
    -webkit-appearance: none;
    position: relative;
    padding-bottom: 5px !important;
}

.button {
    width: 100%;
    max-width: 150px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    background-color: #40b7e6;
    border: 1px solid #40b7e6;
    border-radius: 5px;
    color: #ffffff;
    padding: 7px;
    font-size: 18px;
    font-weight: bold;
    margin-top: 15px;
}

.shipping_price_continer {
    text-align: centercenter;
    font-size: 30px;
    font-weight: bold;
    color: #40b7e6;
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #adb1b4;
    ;
}

#prohibited,
#packaging,
#terms_content {
    display: none;
}

#tems_title,
#prohibitedItems,
#packagingGuidelines,
#prohibitedItems2 {
    cursor: pointer;
}

.custom_text_box {
    font-size: 16px;
    position: absolute;
    z-index: 99999;
    padding: 20px;
    width: 90%;
    height: 90%;
    overflow-y: scroll;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.close {
    width: 25px;
    height: auto;
    float: left;
    margin: 20px;
    cursor: pointer;
}

#map_image_container {
    width: 200px;
    height: auto;
    background-color: gray;
    margin-top: 20px;
    cursor: pointer;
}

#map_image_container img {
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 500px) {
    .progress_title_one {
        right: 5%;
    }
    .progress_title_two {
        right: 21%;
    }
    .progress_title_three {
        right: 37%;
    }
    .progress_title_four {
        right: 51%;
    }
    .progress_title_five {
        right: 69%;
    }
    .progress_title_six {
        right: 85%;
    }
}

@media only screen and (min-width: 1000px) {
    .progress_title_one {
        right: 8%;
    }
    .progress_title_two {
        right: 24%;
    }
    .progress_title_three {
        right: 40%;
    }
    .progress_title_four {
        right: 56%;
    }
    .progress_title_five {
        right: 72%;
    }
    .progress_title_six {
        right: 88%;
    }
}
</style>